import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faWarning } from "@fortawesome/pro-regular-svg-icons";

import "../assets/scss/components/_message-card-section.scss";
import { GenerateImageLink } from "./Image";
import { useProductStore } from "../stores/ProductStore";
import { useCartStore } from "../stores/CartStore";
import Config from "../Config";
import PersonalisedCardImage from "../assets/images/happy-bday-card.jpg";
import { RecordAMessageSection } from "../components/RecordAMessageSection";
import { DateDifference } from "../utilities/DateUtils";
export const MessageCardSection = () => {
	const [charsLeft, setCharsLeft] = useState(200);
	const [isPersonalisedCardModalOpen, setIsPersonalisedCardModalOpen] = useState(false);
	const [messageCardSelected, setMessageCardSelected] = useState(false);

	const { SetMessageCardText, SetMessageCardType, messageCardType, messageCardText, GetAddPersonalisedCardLink, SetPrintzwareId, printzwareId, SetPrice, selectedDeliveryDate, info } = useProductStore(({ SetMessageCardText, SetMessageCardType, messageCardType, messageCardText, GetAddPersonalisedCardLink, SetPrintzwareId, printzwareId, SetPrice, selectedDeliveryDate, info }) => ({
		SetMessageCardText,
		SetMessageCardType,
		messageCardType,
		messageCardText,
		GetAddPersonalisedCardLink,
		SetPrintzwareId,
		printzwareId,
		SetPrice,
		selectedDeliveryDate,
		info
	}));

	const { GetCart } = useCartStore((state) => state);

	useEffect(() => {
		GetCart();
	}, [GetCart]);

	const messageCardSection = useRef(null);

	const ChangeCardType = (type, value = false) => {
		if (type === "standard") {
			SetMessageCardType("standard");
			setMessageCardSelected("standard");
			SetPrintzwareId(null);
			if (value) {
				SetMessageCardText(value);
			}
		} else {
			SetMessageCardType("personalised");
			setMessageCardSelected("personalised");
			SetMessageCardText("");
			if (value) {
				SetPrintzwareId(value);
			}
		}
		setIsPersonalisedCardModalOpen(false);
		SetPrice();
	};

	const [personalisedCardCheck, setPersonalisedCardCheck] = useState(true);

	useEffect(() => {
		let personalisedCardCheck = true; //assume true

		if (info.distributor !== "julian") personalisedCardCheck = false; //if julian distributor

		const currentTime = new Date();

		//no need to run checks if already false
		if (personalisedCardCheck && Config.personalisedCardCutoff[currentTime.getDay()]) {
			//check cutoff in config
			if (Config.personalisedCardCutoff[currentTime.getDay()] <= currentTime.getHours()) {
				//if past cutoff
				const selectedDateDiff = DateDifference(selectedDeliveryDate, currentTime); //get date diff

				if (currentTime.getDay() === 6 && selectedDateDiff.days < 2) personalisedCardCheck = false; //if sat then kill both Sun + Mon

				if (selectedDateDiff.days < 1) personalisedCardCheck = false; //kill tomorrow
			}
		}

		setPersonalisedCardCheck(personalisedCardCheck);
		if (messageCardType === "personalised") ChangeCardType("standard");
	}, [selectedDeliveryDate]);

	const [personalisedCardLink, setPersonalisedCardLink] = useState(GetAddPersonalisedCardLink());

	return (
		<section
			className="message-card-section"
			ref={messageCardSection}
		>
			<strong className="section-title">Let them know who to thank:</strong>
			<p>Select an option below to determine which message card will be provided with your order.</p>

			<div className="message-card-start-container row align-items-stretch justify-content-space-between">
				{personalisedCardCheck && (
					<div className={`message-card-start personalised-card col-12 ${messageCardSelected ? (messageCardSelected === "personalised" ? "selected col-md-8" : "col-md-4") : "col-md-6"}`}>
						<span className="callout">Popular!</span>
						<h1>Personalised Greeting Card {info.free_card === true ? "FREE" : "+£" + Config.personalisedCardPrice}</h1>
						<p className={`col-md-12 ${messageCardSelected ? (messageCardSelected === "standard" ? "selected col-md-8" : "col-md-4") : "col-md-6"}`}>Make this gift extra special with a high quality customised A5 greeting card. Choose from one of our designs and then customise it for your lucky recipient.</p>

						<div className="personalised-card-option">
							<div className="personalised-card-container">
								<div
									className={`personalised-card-cover ${printzwareId ? "card-added" : ""}`}
									style={{ "--background-image": `url('${printzwareId ? `${Config.personalisedThumbUrl}/${printzwareId}.jpg` : GenerateImageLink(PersonalisedCardImage)}')` }}
									onClick={() => {
										setIsPersonalisedCardModalOpen(true);
									}}
								>
									{!printzwareId && (
										<div className="addition-symbol">
											<FontAwesomeIcon icon={faPlus} />
										</div>
									)}
								</div>
							</div>
							<button
								className="col-12"
								onClick={() => {
									setIsPersonalisedCardModalOpen(true);
								}}
							>
								Customise Card{" "}
							</button>
						</div>

						{(!messageCardSelected || messageCardSelected === "standard") && (
							<button
								className="get-started"
								onClick={() => {
									ChangeCardType("personalised");
								}}
							>
								{messageCardSelected === "standard" ? "Switch To This Card" : "Send With This Card"}
							</button>
						)}
					</div>
				)}

				<div className={`message-card-start standard-card col-12 ${messageCardSelected ? (messageCardSelected === "standard" ? "selected col-md-8" : "col-md-4") : "col-md-6"}`}>
					<h1>Included FREE message card</h1>
					<p>What good is a gift if they don't know who sent it! We include a small message card printed with a handwritten effect with all orders, just type out your message and we will do the rest.</p>
					<div className="card-message col-12">
						<div className="field">
							<label>Card Message:</label>

							<div className="textarea-container">
								{messageCardType === "personalised" && (
									<div className="personalised-warning">
										<FontAwesomeIcon icon={faWarning} />
										You have selected a personalised card, if you would like to switch to a standard message card please click below.
										<button
											onClick={() => {
												ChangeCardType("standard");
											}}
										>
											Change to Standard Card
										</button>
									</div>
								)}
								<textarea
									type="text"
									id="card-message"
									maxLength="200"
									rows="4"
									value={messageCardText}
									onChange={(e) => {
										setCharsLeft(200 - e.target.value.length);
										ChangeCardType("standard", e.target.value);
									}}
									disabled={messageCardType === "personalised"}
								/>
								<div className="remaining-characters-section">
									<span className="remaining-characters">{charsLeft}</span> Characters Remaining
								</div>
							</div>
						</div>
					</div>
					{(!messageCardSelected || messageCardSelected === "personalised") && (
						<button
							className="get-started"
							onClick={() => {
								ChangeCardType("standard");
							}}
						>
							{messageCardSelected === "personalised" ? "Switch To This Card" : "Send With This Card"}
						</button>
					)}{" "}
				</div>

				{((messageCardSelected === "personalised" && printzwareId !== "") || (messageCardSelected === "standard" && messageCardText !== "")) && (
					<>
						<hr />
						<RecordAMessageSection />
					</>
				)}
			</div>

			{messageCardSelected && <div className="message-card-options row"></div>}
			<PersonalisedCardModal
				SetMessageCardType={SetMessageCardType}
				personalisedCardLink={personalisedCardLink}
				isPersonalisedCardModalOpen={isPersonalisedCardModalOpen}
				personalisedCardCallback={(printzwareId, editLink = "") => {
					ChangeCardType("personalised", printzwareId);
					setPersonalisedCardLink(editLink);
				}}
				setIsPersonalisedCardModalOpen={setIsPersonalisedCardModalOpen}
				ChangeCardType={ChangeCardType}
			/>
		</section>
	);
};

const PersonalisedCardModal = ({ personalisedCardLink, isPersonalisedCardModalOpen, setIsPersonalisedCardModalOpen, personalisedCardCallback, SetMessageCardType, ChangeCardType }) => {
	const iframeRef = useRef(null);

	const [key, setKey] = useState(0);

	// Reset iframe when link changes
	useEffect(() => {
		if (isPersonalisedCardModalOpen) {
			setKey((prevKey) => prevKey + 1);
		}
	}, [isPersonalisedCardModalOpen]);

	useEffect(() => {
		const handleIframeMessage = (event) => {
			if (!iframeRef.current || event.source !== iframeRef.current.contentWindow) return;
			const data = JSON.parse(event.data);
			if (data.event === "response") {
				// ChangeCardType("personalised", data.printzware_id);
				personalisedCardCallback(data.printzware_id, data.edit_url);
			} else if (data.event === "close") {
				setIsPersonalisedCardModalOpen(false);
			}
		};
		window.addEventListener("message", handleIframeMessage, false);
		return () => {
			window.removeEventListener("message", handleIframeMessage);
		};
	}, []);

	return (
		<div className={`personalised-card-modal ${isPersonalisedCardModalOpen ? "open" : ""}`}>
			<iframe
				key={key}
				title="personalised-card-modal"
				src={personalisedCardLink}
				ref={iframeRef}
			/>
		</div>
	);
};
