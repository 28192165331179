import { ApiRequest } from "./base";

const apiRoute = "reviews";

export const ReviewsApi = {
	async getStoreReviews() {
		return ApiRequest(`${apiRoute}/`, "GET");
	},

	async getReviewLink(orderId) {
		return ApiRequest(`${apiRoute}/leave-review/${orderId}`, "GET");
	}
};
