import React, { useEffect, useState } from "react";

import Config from "../Config";

import { OutputProducts } from "../components/OutputProducts";
import { CategorySorting } from "../components/CategorySorting";
import { CategoryHeader } from "../components/CategoryHeader";

import { useCategoriesStore } from "../stores/CategoriesStore";
import { useMetaStore } from "../stores/MetaStore";

import "../assets/scss/pages/_category.scss";

export const Category = ({ id }) => {
	const { categoryInfo, structuredData, GetCategory, sort } = useCategoriesStore((state) => state);

	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		GetCategory(id);
	}, [GetCategory, id]);

	useEffect(() => {
		if (categoryInfo && categoryInfo.content_id !== 0) {
			setIsLoaded(true);

			useMetaStore.getState().SetMeta({
				title: categoryInfo?.meta?.[id]?.title?.trim() || categoryInfo?.name || "",
				description: categoryInfo?.meta?.[id]?.description?.trim() || categoryInfo?.footer_content?.trim() || "",
				h1: categoryInfo?.meta?.[id]?.h1_content?.trim() || categoryInfo?.name || "",
				h2: categoryInfo?.meta?.[id]?.h2_content?.trim() || "",
				footerContent: categoryInfo?.meta?.[id]?.footer_content?.trim() || categoryInfo?.footer_content?.trim() || "",
				keywords: categoryInfo?.meta?.[id]?.keywords?.trim() || "",
				canonical: categoryInfo?.category_url?.trim() || "",
				structuredData: structuredData ?? {}
			});
		}
	}, [categoryInfo, id]);

	const categorySpecials = (id, specials = false) => {
		let specialClasses = [];
		let specialCallouts = "";

		switch (id) {
			case 155:
				specialClasses.push("rene-category");
				specialCallouts = "rene";
				break;
			default:
				break;
		}

		return specials !== true ? specialClasses.join(" ") : specialCallouts;
	};

	return (
		<div className={`category container ${categoryInfo.name ? categoryInfo.name.replaceAll(" ", "-").toLowerCase() : ""} ${sort ? sort.replaceAll(" ", "-").replaceAll(":", "").toLowerCase() : ""} ${categorySpecials(id)}`}>
			<CategoryHeader specialName={categorySpecials(id, true)} />
			{isLoaded && <CategorySorting />}
			<div className={`category-products`}>
				{categoryInfo ? (
					<OutputProducts
						products={categoryInfo.products_in_category}
						specialName={categorySpecials(id, true)}
						aboveFold={true}
						lazy={true}
					/>
				) : (
					<h1>No Products</h1>
				)}
			</div>
		</div>
	);
};

export default Category;
