import { useState } from "react";

import { BraintreeDropin } from "./BraintreeDropin";
import { InvoicePayment } from "./InvoicePayment";
import { PayPal } from './PayPal';

import Config from "../Config";
import Clearpay from "./Clearpay";

export const PaymentOptions = ({ totals, userInfo, cart }) => {
	if (cart.length === 0) {
		return null; // No items in the cart, render nothing
	}

	const hasSubscription = cart.some((item) => Config.recurringPaymentOptions.includes(item.size));

	return (
		<>
			{totals.grandTotal !== 0 && <BraintreeDropin />}

			{totals.grandTotal !== 0 && Config.paymentMethodsEnabled.paypalEnabled === true && hasSubscription === false &&  <PayPal />}

			{Config.paymentMethodsEnabled.clearpayEnabled === true && totals.grandTotal !== 0 && hasSubscription === false && <Clearpay />}

			{((userInfo.has_invoice && userInfo.has_invoice === "Yes") || totals.grandTotal === 0) && hasSubscription === false && (
				<InvoicePayment
					grandTotal={totals.grandTotal}
					isStaff={userInfo.is_staff === "Yes"}
				/>
			)}
		</>
	);
};
