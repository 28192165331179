import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { PaymentApi } from "../api/PaymentApi";
import Field from "./Field";

import "../assets/scss/components/_invoice-payment.scss";

const invoicePaymentOptions = ["Test Order", "Phone Order", "Corporate Order", "Blogger", "Photography", "Personal Order", "Competition Winner", "Amazon Order", "Other"];

export const InvoicePayment = ({ grandTotal, isStaff }) => {
	const navigate = useNavigate();
	const [invoiceReason, SetInvoiceReason] = useState("");
	const [showBraintreeReference, setShowBraintreeReference] = useState(false);
	const [braintreeReference, setBraintreeReference] = useState("");

	const handleInvoiceCheckout = async (invoiceOption) => {
		try {
			const payment = await PaymentApi.CheckoutInvoice(invoiceOption, braintreeReference);
			if (payment.result === "success") {
				navigate("/cart/payment-success");
			}
		} catch (error) {
			console.error("Invoice Payment Error", error);
		}
	};

	useEffect(() => {
		if (grandTotal === 0) {
			SetInvoiceReason("Other");
		} else {
			SetInvoiceReason("");
		}
	}, [grandTotal]);

	useEffect(() => {
		if(invoiceReason === "Phone Order") {
			setShowBraintreeReference(true);
		} else {
			setShowBraintreeReference(false);
		}
	}, [invoiceReason]);

	return (
		<div className="invoice-payment">
			{grandTotal !== 0 && isStaff && (
				<h3>
					Invoice Order
					<hr />
				</h3>
			)}

			{grandTotal !== 0 && (
				<div className="invoice-select">
					<select
						value={invoiceReason}
						onChange={(e) => SetInvoiceReason(e.target.value)}
						className={`invoice-select ${isStaff ? "" : "hidden"}`}
					>
						<option value="">=== Select Reason ===</option>
						{invoicePaymentOptions.map((option, index) => (
							<option
								key={index}
								value={option}
							>
								{option}
							</option>
						))}
					</select>
				</div>
			)}

			{showBraintreeReference && (
				<div className="braintree-reference">
					<Field
						type="text"
						label="Braintree Reference"
						value={braintreeReference}
						onChange={(e) => setBraintreeReference(e.target.value)}
					/>
				</div>
			)}

			<button
				className={`btn invoice-button ${!invoiceReason && isStaff && grandTotal !== 0 ? "hidden" : ""}`}
				onClick={() => handleInvoiceCheckout(invoiceReason)}
			>
				{grandTotal !== 0 && isStaff ? "Checkout By Invoice" : "Finalise Order"}
			</button>
		</div>
	);
};
