import React, { useEffect, useState } from "react";

import { StaticApi } from "../api";
import { useMetaStore } from "../stores/MetaStore";
import { PageHeader } from "../components/PageHeader";

import "../assets/scss/pages/_static.scss";

export const Static = ({ id }) => {
	const [staticInfo, setStaticInfo] = useState({});

	useEffect(() => {
		StaticApi.getInfo(id).then(({ page }) => {
			setStaticInfo(page);
		});
	}, [id]);

	useEffect(() => {
		useMetaStore.getState().SetMeta({
			title: staticInfo?.title ?? ""
		});
	}, [staticInfo]);

	return (
		staticInfo &&
		staticInfo.title && (
			<div className={`static ${staticInfo.title.replaceAll(" ", "-").toLowerCase()}`}>
				<PageHeader title={staticInfo.title} />

				<div
					className="container page-content"
					dangerouslySetInnerHTML={{ __html: staticInfo.content }}
				></div>
			</div>
		)
	);
};
