import { ApiRequest } from "./base";

const apiRoute = "payment";

export const PaymentApi = {
	async CheckoutInvoice(invoiceReason, braintreeReference = '') {
		const data = {
			invoice_reason: invoiceReason
		};
		if (braintreeReference) {
			data.invoice_payment_reference = braintreeReference;
		}
		const { payment } = await ApiRequest(`${apiRoute}/invoice/checkout`, "POST", data);
		return payment;
	},
	async PaypalSaveClient() {
		const { payment } = await ApiRequest(`${apiRoute}/paypal/save-client`, "POST");
		return payment.result.result.id;
	},
	async PaypalDoSale() {
		const { payment } = await ApiRequest(`${apiRoute}/paypal/sale`, "POST");
		return payment.result;
	}
};
