import React, { useRef, useEffect, useState } from "react";

import Config from "../Config";

export const GenerateImageLink = (src, width) => {
	const { liveUrl, baseUrl, cloudinaryUrl } = Config;

	if (src?.includes("/src/assets")) {
		return src;
	}

	if(src?.includes("/assets/") && Config.baseUrl.includes(".local")) {
		return src;
	}

	if (src?.includes("/assets/")) {
		const link = width ? `${cloudinaryUrl},w_${width}` : `${cloudinaryUrl}`;
		return `${link}/${baseUrl}${src}`;
	}

	if (src?.includes(liveUrl)) {
		const link = width ? cloudinaryUrl + ",w_" + width + "/" : cloudinaryUrl + "/";
		return link + src;
	}

	let link = src;
	link = link?.replace("react.staging.", "www.");
	link = link?.replace("staging.", "www.");
	link = link?.replace(".local", ".co.uk");
	link = width ? cloudinaryUrl + ",w_" + width + "/" + link : cloudinaryUrl + "/" + link;
	return link;
};

export const Image = ({ src, alt, className, width, returnOnly = false, imported = false, ...props }) => {
	const [imageError, setImageError] = useState(0);
	const imageElement = useRef(null);

	useEffect(() => {
		setImageError(imageError + 1);
	}, []);

	const ImageFallback = () => {
		if (imageElement && imageElement.current) {
			imageElement.current.src = "https://placehold.co/150";
		}
	};

	let imageUrl = src;
	if (!imported) {
		imageUrl = GenerateImageLink(src, width);
	}

	return returnOnly ? (
		imageUrl
	) : (
		<img
			src={imageUrl}
			alt={alt}
			className={className}
			onError={() => (imageError <= 3) && ImageFallback()}
			ref={imageElement}
			{...props}
			draggable="false"
			title={alt}
		/>
	);
};
