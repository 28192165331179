import { create } from "zustand";
import { CategoriesApi } from "../api";
import { useMetaStore } from "./MetaStore";
import { persist, devtools } from "zustand/middleware";
import _ from "lodash"; // Import lodash for deep cloning

const allTags = {
	colours: ["red", "green", "yellow", "blue", "purple", "pink", "white", "orange"],
	stems: ["lilies", "rose", "freesia", "germini", "orchids", "tulips", "carnations", "daisies", "sunflowers", "peonies", "crysanthemums"]
};

const initialState = {
	categoryInfo: {},
	homepageInfo: [],
	sort: "our favourites",
	tags: {
		colours: [],
		stems: []
	},
	availableSorts: ["our favourites", "newest", "price: low to high", "price: high to low"],
	availableTags: {
		colours: [],
		stems: []
	},
	structuredData: {}
};

const useCategoriesStore = create(
	persist(
		devtools((set, get) => ({
			..._.cloneDeep(initialState), // Use deep clone for initial state
			async GetHomepage() {
				const { content_blocks, meta } = await CategoriesApi.getContentBlocks();
				set({ homepageInfo: content_blocks });
				useMetaStore.getState().SetMeta({
					title: meta?.title?.trim() || "123 Flowers | Flower Delivery as Easy as 123 | Guaranteed Fresh Flowers",
					description: meta?.description?.trim() || "Flower Delivery has never been easier. Premium Flowers, no hassle timely delivery | 123 Flowers",
					keywords: meta?.keywords?.trim() || "order flowers online, flower delivery, fresh flowers",
					h1: meta?.h1_content?.trim() || "123 Flowers: Flower Delivery as Easy as 123",
					h2: meta?.h2_content?.trim() || "Stunning Flower Delivery for Every Occasion and Sentiment",
					canonical: meta?.canonical_url?.trim() || "https://www.123-flowers.co.uk"
				});
			},
			async GetCategory(id) {
				const { category, structuredData } = await CategoriesApi.getCategory(id);
				set({ categoryInfo: category, structuredData: structuredData });
				return category;
			},
			ResetCategoryInfo() {
				set({ categoryInfo: _.cloneDeep(initialState.categoryInfo) }); // Deep clone to reset
				return {};
			},
			SetActiveTags(filters) {
				set({ tags: _.cloneDeep(filters) }); // Deep clone to set active tags
			},
			ResetActiveTags() {
				set({ tags: _.cloneDeep(initialState.tags) }); // Deep clone to reset tags
			},
			AddAvailableTags(tags) {
				tags.forEach((tag) => {
					Object.keys(allTags).forEach((tagType) => {
						let currentArr = _.cloneDeep(get().availableTags[tagType] ?? []); // Deep clone current array
						if (allTags[tagType].indexOf(tag) !== -1) {
							if (currentArr.indexOf(tag) !== -1) return;
							currentArr.push(tag);
						}

						set({ availableTags: { ...get().availableTags, [tagType]: currentArr } });
					});
				});
			},
			SetActiveSort(sort) {
				set({ sort: sort });
			}
		})),
		{
			name: "category-store"
		}
	)
);

export { useCategoriesStore };
